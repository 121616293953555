export default {
  state: () => ({
    concerns: null
  }),

  actions: { 
    setConcerns: ({ commit }, concerns) => {
      commit('SET_CONCERNS', concerns)
    }
  },

  mutations: { 
    SET_CONCERNS: (state, concerns) => {
      state.concerns = concerns
    }
  }
}