export let isSticky = false

const getOffsetTop = el => {
    let location = 0;
    if (el.offsetParent) {
        do {
            location += el.offsetTop;
            el = el.offsetParent;
        } while (el);
    }
    return location >= 0 ? location : 0;
};

export const handleSticky = (component, stickyData) => {

    const scrollTop = window.scrollY
    const stickyEls = document.querySelectorAll('.sticky')
    const { stickyEl } = component.$refs

    if (!stickyEl) return null

    if (window.innerWidth < 769) {
        stickyEl.classList.remove('fixed')
        stickyEl.classList.remove('fixed-top')
        stickyEl.classList.remove('stuck')

        if (isSticky) { isSticky.outerWidth = '100%' }
    } else {
        if (
            stickyEl &&
            scrollTop > getOffsetTop(stickyEl.parentNode) - 30 &&
            scrollTop < getOffsetTop(stickyEl.parentNode) + stickyEl.parentNode.offsetHeight
        ) {
            isSticky = stickyEl

            const otherEls = [...stickyEls].filter(el => el !== isSticky)

            otherEls.forEach(el => {

                el.classList.remove('fixed')
                el.classList.remove('fixed-top')

            })
        }

        if (isSticky) {
            handleStickyState(scrollTop, stickyData)
        }
    }
}

export const handleStickyState = (scrollTop, stickyData) => {
    const {
        stickyEl,
        stickyHeight,
        par,
        parHeight,
        parBot,
        parSticky
    } = stickyData

    const stickyTop    = getOffsetTop(stickyEl)
    const parTop       = getOffsetTop(par)

    par.style.minHeight = `${stickyHeight}px`
    stickyEl.style.width  = `${par.getBoundingClientRect().width - 40}px`

    if (
        scrollTop > parTop - 30 &&
        scrollTop < parBot - stickyHeight - 30
    ) {
        stickyEl.classList.add('fixed-top')
        stickyEl.classList.remove('stuck')
    } else if (
        scrollTop >= parBot - stickyHeight - 30
    ) {
        stickyEl.classList.add('stuck')
        stickyEl.classList.remove('fixed-top')
    } else {
        stickyEl.classList.remove('stuck')
        stickyEl.classList.remove('fixed-top')
    }
}

export const getStickyElData = (el) => {
    if (!el) return null

    return {
        stickyEl     : el,
        stickyHeight : el.offsetHeight,
        stickyTop    : getOffsetTop(el),
        par          : el.parentNode,
        get parTop() {
            return getOffsetTop(this.par)
        },
        get parHeight() {
            return this.par.offsetHeight
        },
        get parBot() {
            return this.parTop + this.parHeight
        },
        get parSticky() {
             return this.parTop + this.stickyHeight
        },
    }
}

export const handleMultipleStickyEls = (stickyEl, scrollTop, arr) => {
    if (scrollTop > getOffsetTop(stickyEl.parentNode) - 30 &&
        scrollTop < getOffsetTop(stickyEl.parentNode) + stickyEl.parentNode.offsetHeight) {
            isSticky = stickyEl

            const others = [...arr].filter(el => el !== isSticky)

            others.forEach(el => {
                el.classList.remove('fixed')
                el.classList.remove('fixed-top')
            })
        }
}
