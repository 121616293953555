import Vue from 'vue'

// Global
export const SET_LOCALE = (state, locale) => {
  state.locale = locale
}

export const SET_GLOBAL_DATA = (state, globalData) => {
  state.globalData = globalData
}

export const SET_SHOP_MENUS = (state, shopMenus) => {
  state.header.links.shop = shopMenus
}

export const SET_ABOUT_MENU_DATA = (state, about) => {
  const aboutState = state.header.links.learn.find(item => {
    return item.type == 'about'
  })

  aboutState.description = about.description
  aboutState.image = about.image
  aboutState.menu = about.menu
}

export const SET_BLOG_MENU_DATA = (state, blog) => {
  const blogState = state.header.links.learn.find(item => {
    return item.type == 'blog'
  })

  blogState.description = blog.description
  blogState.links = blog.links
  blogState.articles = blog.articles
}

export const SET_STUDIOS_MENU_DATA = (state, studios) => {
  const studiosState = state.header.links.studios[0]

  studiosState.heading = studios.heading
  studiosState.description = studios.description
  studiosState.image = studios.image
  studiosState.links = studios.links
}

export const SET_CART_DATA = (state, cart) => {
  state.cart.menu = cart.menu
  state.cart.callout = cart.callout
  state.cart.showDiscountNote = cart.showDiscountNote
  state.cart.freeShippingThreshold = cart.freeShippingThreshold
  state.cart.showSamples = cart.showSamples
  state.cart.samples = cart.samples
  state.cart.showCarousel = cart.showCarousel
  state.cart.carousel = cart.carousel
  state.cart.enableGiftWrap = cart.enableGiftWrap
  state.cart.giftWrapProduct = cart.giftWrapProduct
  state.cart.enableTieredDiscount = cart.enableTieredDiscount
  state.cart.valueProps = cart.valueProps
}

export const SET_SEARCH_DATA = (state, search) => {
  state.search.menu = search.menu
  state.search.trendingProduct = search.trendingProduct
  state.search.callout = search.callout
}

// Meta
export const SET_META_INFO = (state, metaInfo) => {
  state.meta = metaInfo
}

// User/Customer
export const SET_ACCESS_TOKEN = (state, token) => {
  state.user.shopifyToken = token
}

export const REMOVE_ACCESS_TOKEN = state => {
  state.user.shopifyToken = {}
}

export const SET_USER_DATA = (state, data) => {
  state.user.data = data
}

export const REMOVE_USER_DATA = (state, data) => {
  state.user.data = {}
}

export const CLOSE_COOKIE_BAR = state => {
  state.user.isCookieBarOpen = false
}

export const OPEN_COOKIE_BAR = state => {
  state.user.isCookieBarOpen = true
}

// Header
export const OPEN_HEADER_MENU = (state, type) => {
  state.header.isMenuOpen = true
  state.header.activeMenu = type
  state.header.isHovered = true;
}

export const SET_HEADER_MENU_EXPAND = state => {
  state.header.isExpand = true
}

export const SET_HEADER_MENU_DROPDOWN = state => {
  state.header.isDropdown = true
}

export const SET_REVIEW_EXPAND_INFO = (state, info) => {
  state.header.review.product = info.product
  state.header.review.productId = info.productId
}

export const PRE_CHECK_REGISTER_NEWSLETTER = (state, info) => {
  state.header.preCheckRegisterNewsletter = true
}

export const CLOSE_HEADER_MENU = (state, isHover) => {
  state.header.isHovered = false;
  state.header.review.product = null
  state.header.review.productId = null

  setTimeout(
    function () {
      if (!state.header.isHovered) {
        state.header.isMenuOpen = false
        state.header.activeMenu = null
        state.header.isDropdown = false
      }
    },
    isHover == true ? 150 : 0
  )

  setTimeout(function () {
    state.header.isExpand = false
  }, 300) // This stops the z-index on the header from changing too early
}

export const CLOSE_HEADER_MENU_IMMEDIATELY = state => {
  state.header.isHovered = false;
  state.header.isMenuOpen = false
  state.header.activeMenu = null
  state.header.isDropdown = false

  setTimeout(function () {
    state.header.isExpand = false
  }, 300) // This stops the z-index on the header from changing too early
}

export const OPEN_HEADER_SUBMENU = (state, type) => {
  state.header.isSubmenuHovered = true
  state.header.isSubmenuOpen = true
  state.header.activeSubmenu = type
}

export const CLOSE_HEADER_SUBMENU = state => {
  state.header.isSubmenuHovered = false

  if (!state.header.isSubmenuHovered) {
    state.header.isSubmenuOpen = false
    state.header.activeSubmenu = null
  }
}

export const OPEN_HEADER_MOBILE_SUBMENU = (state, type) => {
  state.header.isMobileSubmenuOpen = true
  state.header.activeMobileSubmenu = type
}

export const CLOSE_HEADER_MOBILE_SUBMENU = state => {
  state.header.isMobileSubmenuOpen = false
  state.header.activeMobileSubmenu = null
}

// Cart/Checkout
export const SET_CART_ITEMS = (state, items) => {
  state.cart.items = items
}

export const UPDATE_CHECKOUT = (state, payload) => {
  state.cart.checkout = payload
}

export const ADD_ITEM_TO_CART = (state, payload) => {
  const cartItems = [...state.cart.items, payload]

  //Z2lkOi8vc2hvcGlmeS9TZWxsaW5nUGxhbi8xMDU3NDU2MTg2

  state.cart.items = cartItems

  if (process.client) {
    localStorage.setItem(
      `gee_cart_${state.locale}`,
      JSON.stringify(state.cart.items)
    )
  }
}

export const REMOVE_ITEM_FROM_CART = (state, variantId) => {
  const cartItems = [...state.cart.items]
  const updatedCartItems = cartItems.filter(item => item.variantId != variantId)

  state.cart.items = updatedCartItems

  if (process.client) {
    localStorage.setItem(
      `gee_cart_${state.locale}`,
      JSON.stringify(state.cart.items)
    )
  }
}

export const UPDATE_ITEM_QUANTITY = (state, payload) => {
  const { type, product, quantity, isGiftWrap } = payload
  const variantId = product ? product.variantId : payload.variantId

  const { items } = state.cart
  const itemIndex = items.findIndex(item => item.variantId === variantId)

  let newQuantity
  if (type == 'update' || isGiftWrap) {
    newQuantity = isGiftWrap ? 1 : quantity
  } else {
    newQuantity = items[itemIndex].quantity + quantity
  }

  const newEl = { ...items[itemIndex], quantity: newQuantity }

  Vue.set(state.cart.items, itemIndex, newEl)

  if (process.client) {
    localStorage.setItem(
      `gee_cart_${state.locale}`,
      JSON.stringify(state.cart.items)
    )
  }
}

// Modal
export const OPEN_MODAL = (state, type) => {
  state.modal.isOpen = true
  state.modal.activeModal = type
}

export const SET_QUICK_SHOP_MODAL = (state, payload) => {
  state.modal.quickShop.type = payload.type
  state.modal.quickShop.activeProductUid = payload.activeProductUid

  if (payload.modalTitle) {
    state.modal.quickShop.modalTitle = payload.modalTitle
  }

  if (payload.modalProducts) {
    state.modal.quickShop.modalProducts = payload.modalProducts
  }

  if (payload.activeProductVariantId) {
    state.modal.quickShop.activeProductVariantId =
      payload.activeProductVariantId
  }
}

export const SET_QUICK_SHOP_MODAL_TITLE = (state, title) => {
  state.modal.quickShop.modalTitle = title
}

export const SET_QUICK_SHOP_MODAL_PRODUCTS = (state, products) => {
  state.modal.quickShop.modalProducts = products
}

export const CHANGE_QUICK_SHOP_MODAL_ACTIVE_PRODUCT_ID = (
  state,
  activeProductUid
) => {
  state.modal.quickShop.activeProductUid = activeProductUid
  state.modal.quickShop.activeProductVariantId = null
}

export const UPDATE_QUICK_SHOP_ACTIVE_PRODUCT = (state, { uid, variantId }) => {
  state.modal.quickShop.activeProductUid = uid
  state.modal.quickShop.activeProductVariantId = variantId
}

export const SET_BACK_IN_STOCK_MODAL = (state, product) => {
  state.modal.backInStockProduct = product
}

export const SET_CONTACT_MODAL_SUBJECT = (state, subject) => {
  state.modal.contact.subject = subject
}

export const SET_BOOKING_MODAL_INFO = (state, payload) => {
  state.modal.contact.booking.studio = payload.studio
  state.modal.contact.booking.service = payload.service
}

export const CLOSE_MODAL = state => {
  state.modal.isOpen = false
  state.modal.activeModal = null
  state.modal.quickShop.activeProductUid = null
  state.modal.quickShop.activeProductVariantId = null
  state.modal.contact.subject = null
  state.modal.contact.booking.studio = null
  state.modal.contact.booking.service = null
}

export const SET_SMILE_DATA = (state, payload) => {
  state.smile = payload
}

export const REMOVE_SMILE_DATA = state => {
  state.smile = null
}

export const SET_RECHAGRE_DATA = (state, payload) => {
  state.user.recharge = payload
}
